import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"

const Header = () => (
  <Navbar bg="light" variant="light" sticky="top" className="shadow">
    <Nav className="mr-auto font-weight-bold font-header">
      <Nav.Link className="cl-identity hovcl-darkviolet" as={Link} to="/">
        Home
      </Nav.Link>
      <Nav.Link className="cl-identity hovcl-darkviolet" as={Link} to="/consultoria">
        Consultoria
      </Nav.Link>
      <Nav.Link className="cl-identity hovcl-darkviolet" href="https://registudy.com/" target="_blank" rel="noopener noreferrer">
        Registudy
      </Nav.Link>
      <Nav.Link className="cl-identity hovcl-darkviolet" as={Link} to="/materiais">
        Materiais
      </Nav.Link>
      <Nav.Link className="cl-identity hovcl-darkviolet" as={Link} to="/videos">
        Vídeos
      </Nav.Link>
    </Nav>
  </Navbar>
)

export default Header
